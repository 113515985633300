import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Agency, AgencyForm, AgencyFormDTO } from 'types/masterData/agencies'
import { serviceNameRegExp } from 'utils/regexp'
import { autocompleteOptionType } from 'utils/zod'

export const defaultValues: AgencyForm = {
  displayName: '',
  serviceName: '',
  aliases: [],
  logoThumbnail: null,
  logoOriginal: null,
}

export const convertToForm = (values: Agency): AgencyForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
  }

export const convertFormToFormDto = (values: AgencyForm): AgencyFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
})

export const displayNameFieldCharacterMaxCount = 256
export const displayNameFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMinCount = 3
export const serviceNameFieldCharacterMaxCount = 256

export const useAgencyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        displayName: zod
          .string()
          .trim()
          .min(
            displayNameFieldCharacterMinCount,
            t('master_data.validation.display_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMinCount,
            }),
          )
          .max(
            displayNameFieldCharacterMaxCount,
            t('master_data.validation.display_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: displayNameFieldCharacterMaxCount,
            }),
          ),
        serviceName: zod
          .string()
          .trim()
          .regex(serviceNameRegExp, t('master_data.validation.service_name.not_valid'))
          .min(
            serviceNameFieldCharacterMinCount,
            t('master_data.validation.service_name.min', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMinCount,
            }),
          )
          .max(
            serviceNameFieldCharacterMaxCount,
            t('master_data.validation.service_name.max', {
              entity: t('common.master_data.entities.agency'),
              characterCount: serviceNameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(zod.union([autocompleteOptionType, zod.string()])),
      }),
    [t],
  )
}
