import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateAgencyApi } from 'api/masterData/mutations/useCreateAgencyApi'
import { useEditAgencyApi } from 'api/masterData/mutations/useEditAgencyApi'
import { useFetchAgencyApi } from 'api/masterData/queries/useFetchAgencyApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { showDeleteAgencyModal } from 'pages/agencies/deleteAgencyModal/DeleteAgencyModal'
import {
  defaultValues,
  convertToForm,
  convertFormToFormDto,
  useAgencyValidationScheme,
} from 'pages/agencies/upsert/utils'
import { handleReloadAgenciesListTable } from 'pages/agencies/utils'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { useToast } from 'providers/toast/ToastProvider'
import { AgencyForm } from 'types/masterData/agencies'
import { routesManager } from 'utils/routesManager'

export const UpsertAgency = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateAgencyApi()
  const { mutateAsync: handleEdit } = useEditAgencyApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const agencyState = state?.data

  const { data: agencyData } = useFetchAgencyApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const agency = agencyData || agencyState

  const form = useForm({
    defaultValues: convertToForm(agency) || defaultValues,
    validationSchema: useAgencyValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: AgencyForm) => {
    try {
      const { data: createdAgency } = await handleCreate({
        agency: convertFormToFormDto(values),
      })

      navigate(routesManager.masterData.agencies.update.getURL({ entryId: createdAgency?.id }))

      await handleReloadAgenciesListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: AgencyForm) => {
    try {
      await handleEdit({
        id: agency!.id,
        agency: convertFormToFormDto(values),
      })

      await handleReloadAgenciesListTable(agency!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.agency') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteAgencyModal({
      agency,
      onAfterDelete: () => {
        navigate(routesManager.masterData.agencies.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {agency && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(agency ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        title={t(`common.${agency ? 'update' : 'create'}_entity`, {
          entity: t('common.master_data.entities.agency'),
        })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={agency?.displayName || ''} />
          <FormTextInput
            name="displayName"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormTextInput
            name="serviceName"
            labelConfig={{
              text: t('master_data.fields.service_name.label'),
            }}
            required
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
