import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateIndustryApi } from 'api/masterData/mutations/useCreateIndustryApi'
import { useEditIndustryApi } from 'api/masterData/mutations/useEditIndustryApi'
import { useFetchIndustryApi } from 'api/masterData/queries/useFetchIndustryApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { useForm } from 'hooks/form/useForm'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { showDeleteIndustryModal } from 'pages/industries/deleteIndustryModal/DeleteIndustryModal'
import {
  defaultValues,
  useIndustryValidationScheme,
  convertToForm,
  convertFormToFormDto,
} from 'pages/industries/upsert/utils'
import { handleReloadIndustriesListTable } from 'pages/industries/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { IndustryForm } from 'types/masterData/industries'
import { routesManager } from 'utils/routesManager'

export const UpsertIndustry = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateIndustryApi()
  const { mutateAsync: handleEdit } = useEditIndustryApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const industryState = state?.data

  const { data: industryData } = useFetchIndustryApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const industry = industryData || industryState

  const form = useForm({
    defaultValues: convertToForm(industry) || defaultValues,
    validationSchema: useIndustryValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: IndustryForm) => {
    try {
      const { data: createdIndustry } = await handleCreate({
        industry: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.industries.update.getURL({ entryId: createdIndustry?.id }))

      await handleReloadIndustriesListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: IndustryForm) => {
    try {
      await handleEdit({
        id: industry!.id,
        industry: convertFormToFormDto(values),
      })

      await handleReloadIndustriesListTable(industry!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteIndustryModal({
      industry,
      onAfterDelete: () => {
        navigate(routesManager.masterData.industries.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {industry && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(industry ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        title={t(`common.${industry ? 'update' : 'create'}_entity`, {
          entity: t('common.master_data.entities.industry'),
        })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
