import { WppCard, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren, ReactNode } from 'react'

import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss'

interface Props extends ComponentProps<typeof WppCard> {
  actionButtons?: ReactNode
  title: string
  className?: string
}

export const UpsertEntityWrapper = ({
  actionButtons,
  title,
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) => (
  <Flex direction="column" gap={24} className={styles.container}>
    <Flex direction="column" gap={8}>
      <Breadcrumbs />
      <Flex justify="between" align="center">
        <WppTypography type="3xl-heading">{title}</WppTypography>
        {actionButtons && <Flex gap={12}>{actionButtons}</Flex>}
      </Flex>
    </Flex>

    <WppCard size="xl" className={clsx(styles.cardWrapper, className)} {...rest}>
      <WppGrid fluid container>
        <WppGrid item all={16}>
          {children}
        </WppGrid>
      </WppGrid>
    </WppCard>
  </Flex>
)
