import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Client, ClientForm, ClientFormDTO } from 'types/masterData/clients'
import { autocompleteOptionType } from 'utils/zod'

export const defaultValues: ClientForm = {
  name: '',
  aliases: [],
  logoThumbnail: null,
  logoOriginal: null,
}

export const convertToForm = (values: Client): ClientForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
  }

export const convertFormToFormDto = (values: ClientForm): ClientFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
})

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 2

export const useClientValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.client'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.client'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(zod.union([autocompleteOptionType, zod.string()])),
      }),
    [t],
  )
}
